import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';

function DebtorsList(props) {
	const { openDebtors, onCloseDebtors, debtors } = props;
	const [loading, setLoading] = useState(false);
	const token = sessionStorage.getItem('Token');

	const handleSendSmsClick = async () => {
		setLoading(true);

		try {
			const confirmSendSMS = window.confirm('Are you sure you want to send SMS messages to debtors?');
			if (confirmSendSMS) {
				try {
					const sendSmsResponse = await axios.get(
						'https://rockmanapi.nexlogssolutions.com/api/smscustomerswithnegativebalance/',
						// 'http://127.0.0.1:8000/api/smscustomerswithnegativebalance/',
						{
							headers: {
								Authorization: `Token ${token}`,
								'Content-Type': 'application/json',
							},
						}
					);
					if (sendSmsResponse.data.length !== 0) {
						alert('SMS sent successfully to all debtors.');
					} else {
						alert('Failed to send SMS. Please try again.');
					}
				} catch (error) {
					alert('Error sending SMS:', error);
				} finally {
					setLoading(false);
				}
			} else {
				setLoading(false);
			}
		} catch (error) {
			alert('Error sending SMS:', error);
			setLoading(false);
		}
	};

	return (
		<Dialog open={openDebtors} onClose={onCloseDebtors}>
			<DialogTitle sx={{ textAlign: 'center', fontSize: 'xx-large', paddingBottom: '1.5rem' }}>
				Debtors List
			</DialogTitle>
			<DialogContent>
				<Button sx={{ width: '100%', mb: 2 }} variant="contained" onClick={handleSendSmsClick}>
					Send SMS
				</Button>
				{loading ? (
					<CircularProgress sx={{ display: 'block', margin: '0 auto' }} />
				) : debtors === null ? (
					<CircularProgress sx={{ display: 'block', margin: '0 auto' }} />
				) : debtors.length !== 0 ? (
					<TableContainer component={Paper}>
						<Table sx={{}} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ paddingLeft: '1.5rem', fontWeight: 600 }}>Username </TableCell>
									<TableCell align="right" sx={{ fontWeight: 600 }}>
										Balance(ghs)
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{debtors.map((debtor) => (
									<TableRow
										key={debtor.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row" sx={{ paddingLeft: '1.5rem' }}>
											{debtor.user_id.username}
										</TableCell>
										<TableCell align="right">
											{Math.round(debtor.tranfer_account_balance)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<Typography variant="h6">There are no debtors in the system</Typography>
				)}
			</DialogContent>
		</Dialog>
	);
}

export default DebtorsList;
