import React, { useState } from 'react';
import FreightListTable from './FreightListTable';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import AddFreightForm from './AddFreightForm';
import AddSMSForm from './AddSMSForm';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import { renderToString } from 'react-dom/server';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles((theme) => ({
	item: {
		paddingTop: '0 !important',
	},
	searchNButtonContainer: { marginBlock: '1.5rem', gap: 0.5 },
}));
const styles = {
	fontFamily: 'sans-serif',
	textAlign: 'center',
};
const colstyle = {
	width: '100%',
};
const testcss = {
	fontSize: '3px',
	color: 'green',
	border: '1px solid black',
	Margin: 0,
	padding: 0,
};
const tableStyle = {
	width: '100%',
};

function GetFreightReport() {
	const [consignmentInAccra, setConsignmentInAccra] = React.useState([]);
	const [freightArray, setFreightArray] = React.useState([]);
	const [error, setError] = React.useState(null);
	const token = sessionStorage.getItem('Token');
	const [selectedValue, setSelectedValue] = useState('');

	React.useEffect(() => {
		axios
			.get('https://rockmanapi.nexlogssolutions.com/api/consignmentlistopenandinaccra/', {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'application/json',
				},
			})
			// .get('http://127.0.0.1:8000/api/consignmentlistopenandinaccra/')
			.then((res) => {
				setConsignmentInAccra(res.data);
			})
			.catch((error) => {
				setError(error);
			});
		return () => {
			setConsignmentInAccra([]);
		};
	}, []);
	console.log('freightArray', freightArray);

	const handleChange = (e) => {
		setSelectedValue(e.target.value);

		function getFreightArray() {
			let consignmentID = e.target.value;
			axios
				.get(
					'https://rockmanapi.nexlogssolutions.com/api/freightreportbyconsignmentid/' + consignmentID + '/',
					{
						headers: {
							Authorization: `Token ${token}`,
							'Content-Type': 'application/json',
						},
					}
				)
				// .get('http://127.0.0.1:8000/api/freightreportbyconsignmentid/' + consignmentID + '/')
				.then((res) => {
					setFreightArray(res.data);
				})
				.catch((error) => {
					setError(error);
				});
		}
		getFreightArray();
		console.log('freightArray', freightArray);
	};

	const print = () => {
		const doc = new jsPDF();

		// It can parse html:
		// <table id="my-table"><!-- ... --></table>
		autoTable(doc, { html: '#my-table' });

		// Or use javascript directly:
		let values = Object.values(freightArray).map((key) => {
			return [
				key.customer_id.user_id.username,
				key.customer_id.freight_account_balance,
				key.total_weight,
				key.total_cost,
				key.payment_id.amount_sent_cedis,
				key.payment_id.balance,
				key.picked_up,
				key.note,
			];
		});
		console.log('values', values);
		autoTable(doc, {
			head: [
				['Customer', 'Transfer Balance', 'Weight', 'Total Cost', 'Amount Paid', 'Balance', 'Picked Up', 'Note'],
			],
			body: values,
		});

		doc.save('table.pdf');
	};
	return (
		<>
			<Select sx={{ width: 100, height: 45 }} value={selectedValue} onChange={handleChange} displayEmpty>
				<MenuItem value="" disabled>
					<em>Select an option</em>
				</MenuItem>
				{consignmentInAccra.map((option) => (
					<MenuItem key={option.id} value={option.id}>
						{option.id}
					</MenuItem>
				))}
			</Select>

			<Button sx={{ width: 100, height: 45, ml: 2 }} variant="contained" onClick={print}>
				print
			</Button>
		</>
	);
}

export default GetFreightReport;
