import React from 'react';
import { dropDownMenu } from './MenuList';
import { Link } from 'react-router-dom';

const TransferDropMenu = () => {
	return (
		<ul >
			{dropDownMenu.map((item) => (
				<li key={item.id}>
					<Link to={item.path} className={item.cName}>{item.title} </Link>
				</li>
			))}
		</ul>
	);
};

export default TransferDropMenu;
