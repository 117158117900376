import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

export default function SupplierPaymentListTable(props) {
	const { supplierPayments, filterSupplierPayments } = props;

	if (supplierPayments.length == 0) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
			>
				<CircularProgress />
			</div>
		);
	}

	const dataToDisplay = filterSupplierPayments.length === 0 ? supplierPayments.slice(0, 300) : filterSupplierPayments;

	return (
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell sx={{ paddingLeft: '1.5rem', fontWeight: 600 }}>Username </TableCell>
						<TableCell align="center" sx={{ fontWeight: 600 }}>
							Goods Cost($)
						</TableCell>
						<TableCell align="center" sx={{ fontWeight: 600 }}>
							Date
						</TableCell>
						<TableCell align="center" sx={{ fontWeight: 600 }}>
							Supplier Name
						</TableCell>
						<TableCell align="center" sx={{ fontWeight: 600 }}>
							Shipping
						</TableCell>
						<TableCell sx={{ fontWeight: 600, width: 400 }}>Other Details </TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{dataToDisplay.map((supplierPayment) => (
						<TableRow key={supplierPayment.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" sx={{ paddingLeft: '1.5rem' }}>
								{supplierPayment.customer_id.user_id.username}
							</TableCell>
							<TableCell align="center">{Math.round(supplierPayment.goods_cost_dollars)}</TableCell>
							<TableCell align="center">{supplierPayment.created_at}</TableCell>
							<TableCell align="center">{supplierPayment.supplier_id.company_name}</TableCell>
							<TableCell align="center">{supplierPayment.shipping_mode}</TableCell>
							<TableCell>{supplierPayment.goods_desc}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
