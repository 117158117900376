import React, { useEffect, useContext } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../components/context/AuthProvider';
import { Typography, Box, Divider, Chip } from '@mui/material';

const Login = () => {
	const { setAuth } = useContext(AuthContext);
	const navigate = useNavigate();
	const LoginPost = async (values) => {
		const loginValue = {
			username: values.username,
			password: values.password,
		};
		try {
			const response = await axios.post('https://rockmanapi.nexlogssolutions.com/api/login/', loginValue);
			// const response = await axios.post('http://127.0.0.1:8000/api/login/', loginValue);
			if (response.status == 200) {
				setAuth(response.data)
				sessionStorage.setItem('Token', response.data.token);
				sessionStorage.setItem('User', JSON.stringify(response.data.user));
				navigate('/');
			}
		} catch (err) {
			if (err.response && err.response.status === 400) {	
				alert(err.response.data.non_field_errors + ' credential maybe deactivated');
			} else {
				console.log('Error:', err);
				alert('An error occurred. Please try again.');
			}
		}
	};

	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		validationSchema: Yup.object({
			username: Yup.string().max(15, 'Must be 15 characters or less'),
		}),
		onSubmit: (values, { resetForm }) => {
			LoginPost(values);
			resetForm();
		},
	});

	// const handleNavigateToRegister = () => {
    //     navigate('/register'); 
    // };
	return (
		<Grid
			container
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'end',
				margin: 'auto',
				padding: 0,
				width: '100%',
				// height: '60vh',
			}}
		>
			<Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center', mt:5 }}>
				<Box sx={{ width: '100%', maxWidth: 150 }}>
					<Typography variant="h4" sx={{}}>
						Welcome
					</Typography>
				</Box>
			</Grid>
			<Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
				<form style={{ width: '30%' }} onSubmit={formik.handleSubmit}>
					<Grid item md={12}>
						<TextField
							size="small"
							margin="dense"
							id="username"
							label="Username"
							fullWidth
							variant="outlined"
							value={formik.values.username}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.username && formik.errors.username}
						/>
					</Grid>
					<Grid item md={12} sx={{ paddingLeft: '0 !important' }}>
						<TextField
							size="small"
							margin="dense"
							type="password"
							id="password"
							label="password"
							fullWidth
							variant="outlined"
							value={formik.values.password}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.password && formik.errors.password}
						/>
					</Grid>
					<Button
						sx={{ width: '100%', height: '2.9rem', marginTop: '.8rem' }}
						variant="contained"
						type="submit"
					>
						Login
					</Button>
				</form>
			</Grid>
		</Grid>
	);
};

export default Login;
