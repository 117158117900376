import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import AddTransferForm from '../components/AddTransferForm';
import AddSupplierPaymentForm from '../components/AddSupplierPaymentForm';
import SupplierPaymentListTable from '../components/SupplierPaymentListTable';
import Sidebar from '../components/Sidebar';

const useStyles = makeStyles((theme) => ({
	item: {
		paddingTop: '0 !important',
	},
	searchNButtonContainer: { marginBlock: '1.5rem' },
}));

function Supplier() {
	const [open, setOpen] = React.useState(false);
	const [openTP, setOpenTP] = React.useState(false);
	const [openSP, setOpenSP] = React.useState(false);
	const [users, setUsers] = React.useState([]);
	const [error, setError] = React.useState('');
	const [supplierPayments, setSupplierPayments] = React.useState([]);
	const [selectedUsername, setSelectedUsername] = React.useState('');
	const [filteredData, setFilteredData] = React.useState([]);
	const token = sessionStorage.getItem('Token');


	const fetchData = () => {
		const customerList = 'https://rockmanapi.nexlogssolutions.com/api/customerlist/';
		// const customerList = 'http://127.0.0.1:8000/api/customerlist/';
		const suppliersList = 'https://rockmanapi.nexlogssolutions.com/api/supplierpayment/';
		// const suppliersList = 'http://127.0.0.1:8000/api/supplierpayment/';

		const getCustomerList = axios.get(customerList, {
			headers: {
				Authorization: `Token ${token}`,
				'Content-Type': 'application/json',
			},
		});
		const getSuppliersList = axios.get(suppliersList, {
			headers: {
				Authorization: `Token ${token}`,
				'Content-Type': 'application/json',
			},
		});

		axios.all([getCustomerList, getSuppliersList]).then(
			axios.spread((...allData) => {
				const allDataCustomerList = allData[0].data;
				const allDataSupplierPaymentList = allData[1].data;

				setUsers(allDataCustomerList);
				setSupplierPayments(allDataSupplierPaymentList);
			})
		);
	};

	React.useEffect(() => {
		fetchData();
	}, [openSP]);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClickOpenSP = () => {
		setOpenSP(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleCloseSP = () => {
		setOpenSP(false);
	};

	const classes = useStyles();

	return (
		<Grid container>
			<Grid id="sidebarContainer" item md={2}>
				<Sidebar />
			</Grid>
			<Grid item md={10}>
				<div id="tranferTabLayout">
					<Grid container className={classes.searchNButtonContainer}>
						<Grid item sm={6} sx={{ paddingLeft: '1.5rem' }}>
							<TextField
								size="small"
								margin="dense"
								id="search"
								label="Search..."
								fullWidth
								variant="outlined"
								value={selectedUsername}
								onChange={(e) => {
									// (e.target.value);
									const searchWord = e.target.value;
									setSelectedUsername(searchWord);
									const newFilter = supplierPayments.filter((value) => {
										return value.customer_id.user_id.username
											.toLowerCase()
											.includes(searchWord.toLowerCase());
									});
									if (searchWord === '') {
										setFilteredData([]);
									} else {
										setFilteredData(newFilter);
									}
								}}
							/>
						</Grid>
						<Grid
							id="tranferButtons"
							item
							sm={6}
							sx={{
								display: 'flex',
								justifyContent: 'end',
								gap: '2rem',
							}}
						>
							<Button variant="contained" startIcon={<AddOutlinedIcon />} onClick={handleClickOpen}>
								Make Transfer
							</Button>
							<AddTransferForm open={open} onClose={handleClose} users={users} setOpenTP={setOpenTP} />
							<Button variant="contained" startIcon={<AddOutlinedIcon />} onClick={handleClickOpenSP}>
								Pay Supplier
							</Button>
						</Grid>
					</Grid>

					<AddSupplierPaymentForm open={openSP} onClose={handleCloseSP} users={users} />
					<SupplierPaymentListTable
						filterSupplierPayments={filteredData}
						supplierPayments={supplierPayments}
					/>
				</div>
			</Grid>
		</Grid>
	);
}

export default Supplier;
