import React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Button from '@mui/material/Button';

function UpdateCustomerOnEditForm(props) {
	const { openEditCustomer, onCloseEditCustomer, customerDetails } = props;
	const token = sessionStorage.getItem('Token');

	const UpdateCustomer = async (values) => {
		const customerValue = {
			user_id: {
				first_name: values.firstName,
				password: 'rockmam',
				username: values.username,
				first_name: values.firstName,
				last_name: values.lastName,
				email: values.email,
				phone_no: values.phoneNumber,
			},
			company_name: values.companyName,
		};
		try {
			const response = await axios.put(
				'https://rockmanapi.nexlogssolutions.com/api/customerdetailsByUsername/' +
					customerDetails.user_id.username +
					'/',
				customerValue,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			// const response = await axios.put(
			// 	'http://127.0.0.1:8000/api/customerdetailsByUsername/' + customerDetails.user_id.username + '/',
			// 	customerValue,
			// 	{
			// 		headers: {
			// 			Authorization: `Token ${token}`,
			// 			'Content-Type': 'application/json',
			// 		},
			// 	}
			// );
			alert('Customer details updated successfully');
		} catch (err) {
			console.error(err.message);
		}
	};

	let initials = {
		firstName: customerDetails ? customerDetails.user_id.first_name : '',
		lastName: customerDetails ? customerDetails.user_id.last_name : '',
		email: customerDetails ? customerDetails.user_id.email : '',
		companyName: customerDetails ? customerDetails.company_name : '',
		phoneNumber: customerDetails ? customerDetails.user_id.phone_no : '',
		username: customerDetails ? customerDetails.user_id.username : '',
		password: customerDetails ? customerDetails.user_id.password : '',
	};
	const formik = useFormik({
		initialValues: initials,
		enableReinitialize: true,
		validationSchema: Yup.object({
			firstName: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
			lastName: Yup.string().max(15, 'Must be 15 characters or less').required('Required'),
			companyName: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
			email: Yup.string()
				.email('Must be a valid email')
				.max(50, 'Must be 15 characters or less')
				.required('Email is required'),
			phoneNumber: Yup.string()
				.min(9, 'Must be exactly 10 numbers')
				.max(9, 'Must be 10 exactly 10 numbers')
				.required('Required'),
			username: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
		}),
		onSubmit: (values, { resetForm }) => {
			UpdateCustomer(values);
			resetForm();
		},
	});
	return (
		<>
			<Dialog open={openEditCustomer}>
				<DialogTitle sx={{ textAlign: 'center', fontSize: 'xx-large', paddingBottom: '1.5rem' }}>
					Customer Registration
				</DialogTitle>
				<DialogContent>
					<Box>
						<form onSubmit={formik.handleSubmit}>
							<Grid container spacing={2}>
								<Grid item sm={6} sx={{ paddingTop: '10px !important' }}>
									<TextField
										sx={{
											'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
											'& .MuiFormHelperText-root': { lineHeight: 0 },
										}}
										helperText={
											formik.touched.firstName && formik.errors.firstName ? (
												<p>{formik.errors.firstName}</p>
											) : null
										}
										size="small"
										margin="dense"
										id="firstName"
										label="First name"
										fullWidth
										variant="outlined"
										value={formik.values.firstName}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.firstName && formik.errors.firstName}
									/>
								</Grid>
								<Grid item sm={6} sx={{ paddingTop: '10px !important' }}>
									<TextField
										sx={{
											'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
											'& .MuiFormHelperText-root': { lineHeight: 0 },
										}}
										helperText={
											formik.touched.lastName && formik.errors.lastName ? (
												<p>{formik.errors.lastName}</p>
											) : null
										}
										size="small"
										margin="dense"
										id="lastName"
										label="Last name"
										fullWidth
										variant="outlined"
										value={formik.values.lastName}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.lastName && formik.errors.lastName}
									/>
								</Grid>
								<Grid item sm={6} sx={{ paddingTop: '10px !important' }}>
									<TextField
										sx={{
											'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
											'& .MuiFormHelperText-root': { lineHeight: 0 },
										}}
										helperText={
											formik.touched.companyName && formik.errors.companyName ? (
												<p>{formik.errors.companyName}</p>
											) : null
										}
										size="small"
										margin="dense"
										id="companyName"
										label="Company Name"
										fullWidth
										variant="outlined"
										value={formik.values.companyName}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.companyName && formik.errors.companyName}
									/>
								</Grid>
								<Grid item sm={6} sx={{ paddingTop: '10px !important' }}>
									<TextField
										sx={{
											'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
											'& .MuiFormHelperText-root': { lineHeight: 0 },
										}}
										helperText={
											formik.touched.email && formik.errors.email ? (
												<p>{formik.errors.email}</p>
											) : null
										}
										size="small"
										margin="dense"
										id="email"
										label="Email"
										type="email"
										fullWidth
										variant="outlined"
										value={formik.values.email}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.email && formik.errors.email}
									/>
								</Grid>
								<Grid item sm={12} sx={{ paddingTop: '10px !important' }}>
									<TextField
										sx={{
											'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
											'& .MuiFormHelperText-root': { lineHeight: 0 },
										}}
										helperText={
											formik.touched.phoneNumber && formik.errors.phoneNumber ? (
												<p>{formik.errors.phoneNumber}</p>
											) : null
										}
										type="number"
										size="small"
										margin="dense"
										id="phoneNumber"
										label="Phone Number"
										fullWidth
										variant="outlined"
										value={formik.values.phoneNumber}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.phoneNumber && formik.errors.phoneNumber}
									/>
								</Grid>
								<Grid item sm={12} sx={{ paddingTop: '10px !important' }}>
									<TextField
										sx={{
											'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
											'& .MuiFormHelperText-root': { lineHeight: 0 },
										}}
										helperText={
											(formik.touched.username && formik.errors.username) || true ? (
												<p>{formik.errors.username}</p>
											) : null
										}
										size="small"
										margin="dense"
										id="username"
										label="Username"
										fullWidth
										variant="outlined"
										value={formik.values.username}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.username && formik.errors.username}
										disabled
									/>
								</Grid>
								<Grid item sm={12} sx={{ paddingTop: '10px !important' }}>
									<TextField
										sx={{
											'& .MuiInputLabel-root': { color: 'rgba(0, 0, 0, 0.6) !important' },
											'& .MuiFormHelperText-root': { lineHeight: 0 },
										}}
										helperText={
											formik.touched.password && formik.errors.password ? (
												<p>{formik.errors.password}</p>
											) : null
										}
										type="password"
										hiddenLabel
										disabled
										size="small"
										margin="dense"
										id="password"
										label="password"
										fullWidth
										variant="outlined"
										value={'test1!@'}
										// onChange={formik.handleChange}
										// onBlur={formik.handleBlur}
										// error={formik.touched.password && formik.errors.password}
									/>
								</Grid>
							</Grid>
							<Box
								sx={{
									gap: '6rem',
									display: 'flex',
									justifyContent: 'center !important',
									paddingBlock: '1rem',
								}}
							>
								<Button sx={{ width: '20%' }} variant="contained" onClick={onCloseEditCustomer}>
									Cancel
								</Button>
								<Button
									sx={{ width: '20%', marginLeft: '0 !important' }}
									variant="contained"
									type="submit"
								>
									Submit
								</Button>
							</Box>
						</form>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default UpdateCustomerOnEditForm;
