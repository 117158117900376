import React, { useEffect, useState } from 'react';
import { Grid, Typography, Select, MenuItem, InputLabel, CircularProgress, Button } from '@mui/material';
import { useFormik } from 'formik';
import Sidebar from '../components/Sidebar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';

const ConsignmentFrieghtReport = () => {
	const [openConsignment, setOpenConsignment] = useState([]);
	const [consignmentFreights, setConsignmentFreights] = useState([]);
	const [loading, setLoading] = useState(false);
	let navigate = useNavigate();
	const token = sessionStorage.getItem('Token');

	const handleGoBack = () => {
		navigate(-1);
	};

	const PostConsignmentSummary = async (values) => {
		setLoading(true);

		const consignmentList = {
			consignment_names: values.selectedValues,
		};
		try {
			const response = await axios.post(
				'https://rockmanapi.nexlogssolutions.com/api/freightconsignmenttotalbyconsignmentlist/',
				consignmentList,
				// 'http://127.0.0.1:8000/api/freightconsignmenttotalbyconsignmentlist/',
				// consignmentList,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			setLoading(false);

			setConsignmentFreights(response.data);
		} catch (err) {
			// Handle Error Here
			setLoading(false);
			alert(err.message);
		}
	};

	const formik = useFormik({
		initialValues: {
			selectedValues: [],
		},
		onSubmit: (values, { resetForm }) => {
			PostConsignmentSummary(values);
			resetForm();
		},
	});

	useEffect(() => {
		axios
			.get('https://rockmanapi.nexlogssolutions.com/api/consignmentlist/', {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'application/json',
				},
			})
			// .get('http://127.0.0.1:8000/api/consignmentlist/', {
			// 	headers: {
			// 		Authorization: `Token ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// })
			.then((res) => {
				setOpenConsignment(res.data);
			})
			.catch((error) => {
				alert('consignment not fetched !!!, contact you administrator');
			});
	}, []);
	return (
		<Grid container>
			<Grid id="sidebarContainer" item md={2}>
				<Sidebar />
			</Grid>
			<Grid item container md={10} sx={{ px: 5 }}>
				<Grid item md={12} sx={{ mt: 3 }}>
					<Button sx={{ width: 200 }} type="submit" variant="contained" onClick={handleGoBack}>
						Back
					</Button>
				</Grid>
				<Grid item md={12}>
					<Box>
						<Typography sx={{ mt: 4 }} variant="h6">
							Freight Debtors
						</Typography>
					</Box>
					<form onSubmit={formik.handleSubmit}>
						<Grid container spacing={2} sx={{ display: 'flex', alignItems: 'flex-end' }}>
							<Grid item md={4}>
								<InputLabel id="demo-mutiple-checkbox-label">Select Consignment</InputLabel>
								<Select
									sx={{ pt: 0.1, height: '2.5rem', width: '100%' }}
									labelId="demo-mutiple-checkbox-label"
									id="selectedValues"
									name="selectedValues"
									multiple
									value={formik.values.selectedValues}
									onChange={formik.handleChange}
									renderValue={(selectedValues) => selectedValues.join(', ')}
								>
									{openConsignment &&
										openConsignment.map((consignment) => (
											<MenuItem key={consignment.id} value={consignment.name}>
												{consignment.name}
											</MenuItem>
										))}
								</Select>
							</Grid>

							<Grid item md={2}>
								<Button fullWidth type="submit" variant="contained">
									Submit
								</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
				<Grid item md={12} sx={{ mt: 5 }}>
					<Box>
						<Typography sx={{ mt: 4 }} variant="p">
							Results
						</Typography>
					</Box>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 100 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ width: 100, paddingLeft: '1.5rem', fontWeight: 600 }}>
										Consignment
									</TableCell>
									<TableCell align="center" sx={{ width: 130, fontWeight: 600 }}>
										Total Cost
									</TableCell>
									<TableCell align="center" sx={{ width: 130, fontWeight: 600 }}>
										Total Paid
									</TableCell>
									<TableCell align="center" sx={{ width: 130, fontWeight: 600 }}>
										Total Balance
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{consignmentFreights.length == 0 ? null : loading ? (
									<CircularProgress sx={{ display: 'block', margin: '0 auto' }} />
								) : (
									consignmentFreights.map((consignment) => (
										<TableRow
											key={consignment.ConsignmentName}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell
												component="th"
												scope="row"
												sx={{ width: 100, paddingLeft: '1.5rem' }}
											>
												{consignment.ConsignmentName}
											</TableCell>
											<TableCell align="center">{Math.round(consignment.TotalCost)}</TableCell>
											<TableCell align="center">{Math.round(consignment.TotalPaid)}</TableCell>
											<TableCell align="center">{Math.round(consignment.TotalBalance)}</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ConsignmentFrieghtReport;
