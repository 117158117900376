import React, { useState, useEffect, useContext } from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Transfer from './pages/Transfer';
import Supplier from './pages/Supplier';
import Freight from './pages/Freight';
import Report from './pages/Report';
import Login from './pages/Login';
import Register from './pages/Register'
import Consignment from './pages/Consignment';
import FreightReport from './pages/FreightReport'
import TransferReport from './pages/TransferReport'
import ConsignmentFrieghtReport from './pages/ConsignmentFrieghtReport'
import { Grid } from '@mui/material';
import './css/Navbar.css';

export function App() {
	return (
		<Router>
			<Navbar />
			<Grid container>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/login" element={<Login />} />
					<Route path="/register" element={<Register />} />
					<Route path="/supplier" element={<Supplier />} />
					<Route path="/freight" element={<Freight />} />
					<Route path="/report-freight" element={<Report />} />
					<Route path="/transfer" element={<Transfer />} />
					<Route path="/consignment" element={<Consignment />} />
					<Route path="/freightreport" element={<FreightReport />} />
					<Route path="/expenses-report" element={<TransferReport />} />
					<Route path="/consignmentfreightreport" element={<ConsignmentFrieghtReport />} />
					
				</Routes>
			</Grid>
		</Router>
	);
}
