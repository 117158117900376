require('file-loader?name=[name].[ext]!./index.html')
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'
import './App.scss'
import { AuthProvider } from './components/context/AuthProvider';


const appElement = document.getElementById('app');

ReactDOM.render(
    <AuthProvider>

    <App />
    </AuthProvider>

, appElement);
