export const menuItem = [
	{
		id: 4,
		test: 'Customer',
		path: '/',
	},
	{
		id: 5,
		test: 'Payment',
		path: '',
	},
	{
		id: 6,
		test: 'Report',
		path: '',
	},
	{
		id: 7,
		test: 'Consignment',
		path: '/consignment',
	},
];

export const dropDownMenu = [
	{ id: 1, title: 'Transfer', path: '/transfer', cName: 'subMenu-item' },
	{
		id: 2,
		title: 'Supplier',
		path: '/supplier',
		cName: 'subMenu-item',
	},
	{
		id: 3,
		title: 'Freight',
		path: '/freight',
		cName: 'subMenu-item',
	},
];
export const reportDropDownMenu = [
	{ id: 10, title: 'Payment Overview', path: '/expenses-report', cName: 'subMenu-item' },
	{
		id: 11,
		title: 'Freight',
		path: '/report-freight',
		cName: 'subMenu-item',
	},
];