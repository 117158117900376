import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import '../css/FreightTable.css';

function AddSMSForm(props) {
	const [loading, setLoading] = React.useState(false);
	const [consignment, setConsignment] = React.useState([]);
	const [error, setError] = React.useState([]);
	const { openSendSMS, onClose } = props;
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	const token = sessionStorage.getItem('Token');

	const PostFreightSMS = async (values) => {
		const freightSMSValue = {
			consignment_number: values.consignmentNumber,
			pickup_date: values.pickupDate,
		};
		console.log(freightSMSValue);
		try {
			const response = await axios.post(
				'https://rockmanapi.nexlogssolutions.com/api/freightsendsms/',
				freightSMSValue,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			// const response = await axios.post('http://127.0.0.1:8000/api/freightsendsms/', freightSMSValue);
			setLoading(false);
			alert(response.data.StatusMessage);
		} catch (err) {
			// Handle Error Here
			throw err.message;
			console.error(err.message);
		}
	};

	const formik = useFormik({
		initialValues: {
			consignmentNumber: 0,
			pickupDate: '',
		},
		validationSchema: Yup.object({
			consignmentNumber: Yup.number().min(1),
			pickupDate: Yup.date().required('Required').min(today, 'Please choose today or future date'),
		}),
		onSubmit: (values, { resetForm }) => {
			setLoading(true);
			PostFreightSMS(values);
			resetForm();
		},
	});

	React.useEffect(() => {
		// Create a variable to track if the component is mounted
		let isMounted = true;

		axios
			.get('https://rockmanapi.nexlogssolutions.com/api/consignmentlistopenandinaccra/', {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'application/json',
				},
			})
			// .get('http://127.0.0.1:8000/api/consignmentlistopenandinaccra/', {
			// 	headers: {
			// 		Authorization: `Token ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// })
			.then((res) => {
				// Check if the component is still mounted before updating state
				if (isMounted) {
					setConsignment(res.data);
				}
			})
			.catch((error) => {
				// Check if the component is still mounted before updating state
				if (isMounted) {
					setError(error);
				}
			});

		// Cleanup function to set isMounted to false when the component unmounts
		return () => {
			isMounted = false;
		};
	}, [openSendSMS]);
	return (
		<>
			<Dialog open={openSendSMS} onClose={onClose}>
				<DialogTitle sx={{ textAlign: 'center', fontSize: 'xx-large', paddingBottom: '1.5rem' }}>
					Send Freight SMS
				</DialogTitle>
				<DialogContent>
					<Box>
						<form onSubmit={formik.handleSubmit}>
							<Grid container spacing={2}>
								<Grid item sm={12} sx={{ paddingTop: '1.5rem !important' }}>
									<TextField
										sx={{ width: '100%' }}
										select
										id="consignmentNumber"
										name="consignmentNumber"
										value={formik.values.consignmentNumber}
										label="Consignment Number"
										placeholder="test"
										variant="outlined"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.consignmentNumber && !!formik.errors?.consignmentNumber}
									>
										<MenuItem key={0} value={0}>
											Select Consignment
										</MenuItem>
										{consignment.map((option) => (
											<MenuItem key={option.id} value={option.id}>
												{option.name}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item sm={12}>
									<TextField
										InputLabelProps={{ shrink: true }}
										label="Pickup Date"
										variant="outlined"
										type="date"
										size="small"
										margin="dense"
										id="pickupDate"
										name="pickupDate"
										fullWidth
										variant="outlined"
										value={formik.values.pickupDate}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.pickupDate && !!formik.errors?.pickupDate}
									/>
								</Grid>
							</Grid>

							<Grid item>
								<Box
									sx={{
										gap: '2rem',
										display: 'flex',
										justifyContent: 'center !important',
										paddingBlock: '1rem',
									}}
								>
									<Button sx={{ width: '40%' }} variant="contained" onClick={onClose}>
										Cancel
									</Button>
									<Button
										sx={{ width: '40%', marginLeft: '0 !important' }}
										variant="contained"
										type="submit"
									>
										Submit
									</Button>
									{loading ? <CircularProgress sx={{ position: 'absolute', top: '50%' }} /> : null}
								</Box>
							</Grid>
						</form>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default AddSMSForm;
