import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

const CreditorsList = (props) => {
	const { openCreditors, onCloseCreditors, creditors } = props;

	return (
		<Dialog open={openCreditors} onClose={onCloseCreditors}>
			<DialogTitle sx={{ textAlign: 'center', fontSize: 'xx-large', paddingBottom: '1.5rem' }}>
				Creditors List
			</DialogTitle>
			<DialogContent>
				{creditors.length !== 0 ? (
					<TableContainer component={Paper}>
						<Table sx={{}} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ paddingLeft: '1.5rem', fontWeight: 600 }}>Username </TableCell>
									<TableCell align="right" sx={{ fontWeight: 600 }}>
										Balance(ghs)
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{creditors.map((creditor) => (
									<TableRow
										key={creditor.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row" sx={{ paddingLeft: '1.5rem' }}>
											{creditor.user_id.username}
										</TableCell>
										<TableCell align="right">
											{Math.round(creditor.tranfer_account_balance)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<Typography variant="h2">there are no creditors in the system</Typography>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default CreditorsList;
