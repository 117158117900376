import React, { useState, useContext } from 'react';
import axios from 'axios';
import { TextField, Button, Box } from '@mui/material';
import AuthContext from './context/AuthProvider';
import { useNavigate } from 'react-router-dom';


const ExchangeRate = () => {
	const [exchangeRate, setExchangeRate] = useState('');
	const [error, setError] = useState(null);
	const { setCurrencyRate } = useContext(AuthContext);
	const tokenFromStorage = sessionStorage.getItem('Token');
	const navigate = useNavigate();


	// const apiUrl = 'http://127.0.0.1:8000/api/currency-rate/';
	const apiUrl = 'https://rockmanapi.nexlogssolutions.com/api/currency-rate/';

	const handlePostRequest = async () => {
		try {
			const response = await axios.post(
				apiUrl,
				{ exchange_rate: parseFloat(exchangeRate) },
				{
					headers: {
						'Authorization': `Token ${tokenFromStorage}`,
						'Content-Type': 'application/json',
					},
				}
			);
			setCurrencyRate(JSON.stringify(response.data, null, 2));
			alert('Currency Added Successfully')
		} catch (err) {
			if (err.response && err.response.status === 401) {
				sessionStorage.setItem('Token', '');
				navigate('/login');
			} else {
				console.log('err', err);
				alert(err.message);
			}
		}
	};

	return (
		<Box sx={{ px: 2, pt: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '70%' }}>
			<TextField
				size="small"
				type="number"
				label="Exchange Rate"
				variant="outlined"
				value={exchangeRate}
				onChange={(e) => setExchangeRate(e.target.value)}
				InputLabelProps={{ style: { fontSize: '0.8rem' } }}
				sx={{ marginBottom: '20px', py: 0 }}
			/>

			<Button
				variant="contained"
				color="primary"
				onClick={handlePostRequest}
				sx={{ textTransform: 'capitalize' }}
			>
				Submit
			</Button>
		</Box>
	);
};

export default ExchangeRate;
