import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import UpdateFreightEdit from './UpdateFreightEdit';

export default function FreightListTable(props) {
	const [checked, setChecked] = useState(false);
	const [open, setOpen] = useState(false);
	const [selectedValue, setSelectedValue] = useState('');
	const [error, setError] = useState('');
	const [currentFreight, setCurrentFreight] = useState({});
	const [openConsignment, setOpenConsignment] = React.useState([]);
	const [consignmentFreightArray, setConsignmentFreightArray] = React.useState([]);
	const [selectedUsername, setSelectedUsername] = React.useState('');
	const [filteredFreight, setFilteredFreight] = React.useState([]);
	const token = sessionStorage.getItem('Token');

	const updateWithCheckedValue = async (checkedValue, id) => {
		const freightPaymentValue = {
			checked: checkedValue,
		};
		try {
			const response = await axios.put(
				'https://rockmanapi.nexlogssolutions.com/api/customerfreightdetails/' + id + '/',
				freightPaymentValue,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			// const response = await axios.put(
			// 	'http://127.0.0.1:8000/api/customerfreightdetails/' + id + '/',
			// 	freightPaymentValue,
			// 	{
			// 		headers: {
			// 			Authorization: `Token ${token}`,
			// 			'Content-Type': 'application/json',
			// 		},
			// 	}
			// );
		} catch (err) {
			alert(err);
		}
	};

	const handleOpenEditModal = (currentFreight1) => {
		setCurrentFreight(currentFreight1);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSelectChange = async (event) => {
		const consignmentID = event.target.value;
		setSelectedValue(consignmentID);
		await axios
			.get('https://rockmanapi.nexlogssolutions.com/api/freightreportbyconsignmentid/' + consignmentID + '/', {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'application/json',
				},
			})
			// .get('http://127.0.0.1:8000/api/freightreportbyconsignmentid/' + consignmentID + '/', {
			// 	headers: {
			// 		Authorization: `Token ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// })
			.then((res) => {
				setConsignmentFreightArray(res.data);
			})
			.catch((error) => {
				setError(error);
			});
	};

	React.useEffect(async () => {
		try {
			const response = await axios.get('https://rockmanapi.nexlogssolutions.com/api/consignmentlist/', {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'application/json',
				},
			});
			// const response = await axios.get('http://127.0.0.1:8000/api/consignmentlist/', {
			// 	headers: {
			// 		Authorization: `Token ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// });
			const consignments = response.data;
			setOpenConsignment(consignments);

			if (consignments.length > 0) {
				const firstConsignmentId = consignments[0].id;
				setSelectedValue(firstConsignmentId);
				await axios
					.get(
						`https://rockmanapi.nexlogssolutions.com/api/freightreportbyconsignmentid/${firstConsignmentId}/`,
						{
							headers: {
								Authorization: `Token ${token}`,
								'Content-Type': 'application/json',
							},
						}
					)
					// .get(`http://127.0.0.1:8000/api/freightreportbyconsignmentid/${firstConsignmentId}/`, {
					// 	headers: {
					// 		Authorization: `Token ${token}`,
					// 		'Content-Type': 'application/json',
					// 	},
					// })
					.then((res) => {
						setConsignmentFreightArray(res.data);
					})
					.catch((error) => {
						setError(error);
					});
			}
		} catch (error) {
			alert('Consignment not fetched, contact your administrator');
		}
	}, []);

	return (
		<>
			<Grid item sm={3} sx={{ paddingLeft: '1.5rem' }}>
				<TextField
					size="small"
					margin="dense"
					id="search"
					label="Search..."
					fullWidth
					variant="outlined"
					value={selectedUsername}
					onChange={(e) => {
						const searchWord = e.target.value;
						setSelectedUsername(searchWord);
						const newFilter = consignmentFreightArray.filter((value) => {
							return value.customer_id.user_id.username.toLowerCase().includes(searchWord.toLowerCase());
						});
						if (searchWord === '') {
							setFilteredFreight([]);
						} else {
							setFilteredFreight(newFilter);
						}
					}}
				/>
			</Grid>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 100 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell align="center" sx={{ fontWeight: 600 }}>
								Pickup
							</TableCell>
							<TableCell sx={{ width: 100, paddingLeft: '1.5rem', fontWeight: 600 }}>Username </TableCell>
							<TableCell align="center" sx={{ width: 100, fontWeight: 600 }}>
								Total Weight
							</TableCell>
							<TableCell align="center" sx={{ width: 130, fontWeight: 600 }}>
								Total Cost($)
							</TableCell>
							<TableCell align="center" sx={{ width: 130, fontWeight: 600 }}>
								Total Paid($)
							</TableCell>
							<TableCell align="center" sx={{ width: 130, fontWeight: 600 }}>
								Balance
							</TableCell>

							<TableCell align="center" sx={{ fontWeight: 600 }}>
								Date
							</TableCell>
							<TableCell align="center" sx={{ fontWeight: 600 }}>
								Package Number
							</TableCell>
							{filteredFreight.length == 0 ? (
								<TableCell align="center" sx={{}}>
									<TextField
										sx={{ width: 200 }}
										select
										label="Select Consignment"
										value={selectedValue}
										onChange={handleSelectChange}
										variant="outlined"
									>
										{openConsignment &&
											openConsignment.map((consignment) => (
												<MenuItem key={consignment.id} value={consignment.id}>
													{consignment.name}
												</MenuItem>
											))}
									</TextField>
								</TableCell>
							) : null}
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredFreight.length == 0
							? consignmentFreightArray.map((customerFreight) => (
									<TableRow
										key={customerFreight.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell align="center" sx={{ width: 50 }}>
											<Checkbox
												checked={customerFreight.picked_up}
												disabled={customerFreight.picked_up}
												onChange={(event) => {
													let checkedValue = event.target.checked;
													setChecked(checkedValue);
													updateWithCheckedValue(checkedValue, customerFreight.id);
												}}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										</TableCell>
										<TableCell
											component="th"
											scope="row"
											sx={{ width: 100, paddingLeft: '1.5rem' }}
										>
											{customerFreight.customer_id.user_id.username}
										</TableCell>
										<TableCell align="center">{customerFreight.total_weight.toFixed(2)}</TableCell>
										<TableCell align="center">{Math.round(customerFreight.total_cost)}</TableCell>
										<TableCell align="center">{Math.round(customerFreight.total_paid)}</TableCell>
										{/* <TableCell align="center">
											{Math.round(customerFreight.total_paid - customerFreight.total_cost)}
										</TableCell> */}
										<TableCell align="center">
											{Math.round(customerFreight.total_balance)}
										</TableCell>
										<TableCell align="center" sx={{ width: 600 }}>
											{customerFreight.date_updated}
										</TableCell>
										<TableCell align="center" sx={{ width: 600 }}>
											{customerFreight.number_of_package}
										</TableCell>
										<TableCell align="center" sx={{}}>
											{/* <Button variant="contained">Note</Button> */}
											<IconButton
												sx={{ width: '50%', textTransform: 'capitalize' }}
												size="medium"
												aria-label="Edit"
												onClick={() => handleOpenEditModal(customerFreight)}
											>
												<EditIcon />
											</IconButton>
										</TableCell>
									</TableRow>
							  ))
							: filteredFreight.map((customerFreight) => (
									<TableRow
										key={customerFreight.id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell align="center" sx={{ width: 50 }}>
											<Checkbox
												checked={customerFreight.picked_up}
												disabled={customerFreight.picked_up}
												onChange={(event) => {
													let checkedValue = event.target.checked;
													setChecked(checkedValue);
													updateWithCheckedValue(checkedValue, customerFreight.id);
												}}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										</TableCell>
										<TableCell
											component="th"
											scope="row"
											sx={{ width: 100, paddingLeft: '1.5rem' }}
										>
											{customerFreight.customer_id.user_id.username}
										</TableCell>
										<TableCell align="center">{customerFreight.total_weight.toFixed(2)}</TableCell>
										<TableCell align="center">{Math.round(customerFreight.total_cost)}</TableCell>
										<TableCell align="center">{Math.round(customerFreight.total_paid)}</TableCell>
										<TableCell align="center">
											{/* {Math.round(customerFreight.total_paid - customerFreight.total_cost)} */}
											{Math.round(customerFreight.total_balance)}
										</TableCell>
										<TableCell align="center" sx={{ width: 600 }}>
											{customerFreight.date_updated}
										</TableCell>
										<TableCell align="center" sx={{ width: 600 }}>
											{customerFreight.shipping_mode}
										</TableCell>
										<TableCell align="center" sx={{ width: 600 }}>
											{customerFreight.number_of_package}
										</TableCell>
										{/* <TableCell align="center" sx={{ width: 100 }}>
										<Button
											sx={{ width: '50%', textTransform: 'capitalize', backgroundColor: 'red' }}
											size="medium"
											variant="contained"
											disabled={customerFreight.isPaid}
											onClick={() =>
												updateWithIsPaidValue(
													customerFreight.id,
													customerFreight.customer_id.user_id.username
												)
											}
										>
											Paid
										</Button>
									</TableCell> */}
										<TableCell align="center" sx={{}}>
											{/* <Button variant="contained">Note</Button> */}
											<IconButton
												sx={{ width: '50%', textTransform: 'capitalize' }}
												size="medium"
												aria-label="Edit"
												onClick={() => handleOpenEditModal(customerFreight)}
											>
												<EditIcon />
											</IconButton>
										</TableCell>
									</TableRow>
							  ))}
					</TableBody>
				</Table>
			</TableContainer>
			<UpdateFreightEdit
				open={open}
				close={handleClose}
				currentFreight={currentFreight}
				selectedConsignmentID={selectedValue}
				setConsignmentFreightArray={setConsignmentFreightArray}
				onClose={props.onClose}
			/>
		</>
	);
}
