import React from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import { menuItem } from './MenuList';
import useMediaQuery from '@mui/material/useMediaQuery';
import TransferDropMenu from './TransferDropMenu';
import ReportDropDownMenu from './ReportDropDownMenu'
import ExchangeRate from '../components/ExchangeRate';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
	drawerSize: {
		width: '100%',
	},
	drawerRoot: { position: 'relative' },
	drawerPaper: {
		position: 'absolute !important',
		height: '100vh !important',
		width: '100%',
		backgroundColor: '#FBB124 !important',
	},
	listFont: {
		fontSize: '1.3rem !important',
		fontWeight: 600,
		marginLeft: '.5rem !important',
	},
}));

function Sidebar() {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Box>
			<Drawer
				className={classes.drawerSize}
				variant="permanent"
				anchor="left"
				classes={{ root: classes.drawerRoot, paper: classes.drawerPaper }}
			>
				<List>
					{menuItem.map((item) => {
						if (item.test === 'Payment') {
							return (
								<div key={item.test}>
									<ListItem button>
										<ListItemText
											key={item.test}
											classes={{ primary: classes.listFont }}
											primary={item.test}
										/>
									</ListItem>
									<TransferDropMenu />
								</div>
							);
						}
						if (item.test === 'Report') {
							return (
								<div key={item.test}>
									<ListItem button>
										<ListItemText
											key={item.test}
											classes={{ primary: classes.listFont }}
											primary={item.test}
										/>
									</ListItem>
									<ReportDropDownMenu />
								</div>
							);
						}
						return (
							<ListItem
								button
								key={item.test}
								onClick={() => {
									navigate(item.path);
								}}
							>
								<ListItemText classes={{ primary: classes.listFont }} primary={item.test} />
							</ListItem>
						);
					})}
				</List>
				<ExchangeRate />
				<Typography variant="p" sx={{fontSize:'.7rem', pl:2.5, mt:5}}>Version 0.12</Typography>

			</Drawer>
		</Box>
	);
}

export default Sidebar;
