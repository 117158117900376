import React from 'react';
import { reportDropDownMenu } from './MenuList';
import { Link } from 'react-router-dom';

const ReportDropDownMenu = () => {
    return (
        <ul >
			{reportDropDownMenu.map((item) => (
				<li key={item.id}>
					<Link to={item.path} className={item.cName}>{item.title} </Link>
				</li>
			))}
		</ul>
    )
}

export default ReportDropDownMenu
