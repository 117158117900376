import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import Sidebar from '../components/Sidebar';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles((theme) => ({
	gridItem: {
		paddingInline: '4rem',
	},
	button: {
		width: '100%',
	},
}));
const Consignment = () => {
	const [consignmentListOpenAndInTurkey, setConsignmentListOpenAndInTurkey] = useState([]);
	const [consignmentListOpenAndInAccra, setConsignmentListOpenAndInAccra] = useState([]);
	const [age, setAge] = useState('');
	const [consignmentName, setConsignmentName] = useState('');
	const [closeConsignment, setCloseConsignment] = useState('');
	const [shippingMode, setShippingMode] = useState('');
	const [update, setUpdate] = useState(false);
	const token = sessionStorage.getItem('Token');

	const handleChange = (event) => {
		setAge(event.target.value);
	};

	const handleChangeShippingMode = (event) => {
		setShippingMode(event.target.value);
	};

	const handleChangeConsignmentName = (event) => {
		setConsignmentName(event.target.value);
	};

	const handleChangeCloseConsignment = (event) => {
		setCloseConsignment(event.target.value);
	};

	const handleOpenConsignment = async () => {
		const consignmentValue = {
			name: consignmentName,
			status: 'open',
			city: 'Turkey',
			timestamp: '',
			updated: '',
			shippingMode: shippingMode,
		};
		console.log('consignmentValue', consignmentValue);
		try {
			const response = await axios.post(
				'https://rockmanapi.nexlogssolutions.com/api/consignmentlist/',
				consignmentValue,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			// const response = await axios.post('http://127.0.0.1:8000/api/consignmentlist/', consignmentValue);

			if (response.data.StatusCode == 99) {
				alert(response.data.StatusMessage);
			} else if (response.data.StatusCode == 500) {
				alert('Consignment name must be unique.');
			} else {
				alert('consignment created successfully');
			}
		} catch (err) {
			throw err.message;
			console.error(err.message);
		}
		setUpdate(!update);
	};
	const handleMoveConsignment = async () => {
		const consignmentValue = {
			city: 'Accra',
		};
		try {
			const response = await axios.put(
				'https://rockmanapi.nexlogssolutions.com/api/consignmentUpdate/' + age + '/',
				consignmentValue,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			// const response = await axios.put(
			// 	'http://127.0.0.1:8000/api/consignmentUpdate/' + age + '/',
			// 	consignmentValue
			// );
			if (response.status == 200) {
				alert('Consignment dispatch to Ghana successfully');
			}
		} catch (err) {
			alert('error contact your administrator');
			throw err.message;
			console.error(err.message);
		}
		setUpdate(!update);
	};

	const handleCloseConsignment = async () => {
		const consignmentValue = {
			status: 'close',
		};
		try {
			const response = await axios.put(
				'https://rockmanapi.nexlogssolutions.com/api/consignmentstatusupdate/' + closeConsignment + '/',
				consignmentValue,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			// const response = await axios.put(
			// 	'http://127.0.0.1:8000/api/consignmentstatusupdate/' + closeConsignment + '/',
			// 	consignmentValue
			// );
			if (response.status == 200) {
				alert('Consignment closed successfully');
			}
		} catch (err) {
			// Handle Error Here
			alert('error contact your administrator');
			throw err.message;
		}
		setUpdate(!update);
	};

	const fetchConsignmentTypeListsData = () => {
		// const consignmentListOpenVariable = 'https://rockmanapi.nexlogssolutions.com/api/consignmentlist/';
		// const consignmentListOpenVariable = 'http://127.0.0.1:8000/api/consignmentlist/';
		const consignmentListOpenAndInTurkeyVariable =
			'https://rockmanapi.nexlogssolutions.com/api/consignmentlistopenandinturkey/';
		// const consignmentListOpenAndInTurkeyVariable = 'http://127.0.0.1:8000/api/consignmentlistopenandinturkey/';
		const consignmentListOpenAndInAccraVariable =
			'https://rockmanapi.nexlogssolutions.com/api/consignmentlistopenandinaccra/';
		// const consignmentListOpenAndInAccraVariable = 'http://127.0.0.1:8000/api/consignmentlistopenandinaccra/';

		// const getConsignmentListOpen = axios.get(consignmentListOpenVariable);
		const getConsignmentListOpenAndInTurkey = axios.get(consignmentListOpenAndInTurkeyVariable, {
			headers: {
				Authorization: `Token ${token}`,
				'Content-Type': 'application/json',
			},
		});
		const getConsignmentListOpenAndInAccra = axios.get(consignmentListOpenAndInAccraVariable, {
			headers: {
				Authorization: `Token ${token}`,
				'Content-Type': 'application/json',
			},
		});

		axios.all([getConsignmentListOpenAndInTurkey, getConsignmentListOpenAndInAccra]).then(
			// axios.all([getConsignmentListOpen, getConsignmentListOpenAndInTurkey, getConsignmentListOpenAndInAccra]).then(
			axios.spread((...allData) => {
				// const allDataConsignmentListOpen = allData[0].data;
				const allDataConsignmentListOpenAndInTurkey = allData[0].data;
				const allDataConsignmentListOpenAndInAccra = allData[1].data;

				// setConsignmentListOpen(allDataConsignmentListOpen);
				setConsignmentListOpenAndInTurkey(allDataConsignmentListOpenAndInTurkey);
				setConsignmentListOpenAndInAccra(allDataConsignmentListOpenAndInAccra);
			})
		);
	};
	React.useEffect(() => {
		fetchConsignmentTypeListsData();
	}, [update]);

	const classes = useStyles();

	return (
		<Grid container>
			<Grid id="sidebarContainer" item md={2}>
				<Sidebar />
			</Grid>
			<Grid item md={10}>
				<Grid
					container
					sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
				>
					<Grid item sm={4} className={classes.gridItem} sx={{}}>
						<Box></Box>
						<FormControl fullWidth sx={{ marginBottom: '.5rem' }}>
							<Typography sx={{ marginTop: '.2rem' }}> Open Consignment </Typography>
							<TextField
								size="normal"
								margin="dense"
								id="name"
								label="Enter Consignment Name"
								fullWidth
								variant="outlined"
								value={consignmentName}
								onChange={handleChangeConsignmentName}
							/>
							<TextField
								select
								size="normal"
								margin="dense"
								id="shippingMode"
								label="Enter shipping mode"
								fullWidth
								variant="outlined"
								value={shippingMode}
								onChange={handleChangeShippingMode}
							>
								<MenuItem value="sea"> Sea </MenuItem>
								<MenuItem value="air"> Air </MenuItem>
							</TextField>
						</FormControl>
						<Button className={classes.button} variant="contained" onClick={handleOpenConsignment}>
							Open
						</Button>
					</Grid>
					<Grid item sm={4} className={classes.gridItem} sx={{}}>
						<Typography sx={{ marginBottom: '1rem' }}>Consignment in Turkey</Typography>

						<FormControl fullWidth sx={{ marginBottom: '.5rem' }}>
							<InputLabel id="demo-simple-select-label">Enter consignment</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={age}
								label="Enter consignment"
								onChange={handleChange}
							>
								{consignmentListOpenAndInTurkey.length > 0
									? consignmentListOpenAndInTurkey.map((consignmentInTurkey) => (
											<MenuItem key={consignmentInTurkey.id} value={consignmentInTurkey.id}>
												{consignmentInTurkey.name}
											</MenuItem>
									  ))
									: null}
							</Select>
						</FormControl>
						<Button className={classes.button} variant="contained" onClick={handleMoveConsignment}>
							Dispatch
						</Button>
					</Grid>
					<Grid item sm={4} className={classes.gridItem} sx={{}}>
						<Typography sx={{ marginBottom: '1rem' }}>Consignment in Accra</Typography>
						<FormControl fullWidth sx={{ marginBottom: '.5rem' }}>
							<InputLabel id="demo-simple-select-label">Enter consignment</InputLabel>
							<Select
								value={closeConsignment}
								label="Enter consignment"
								onChange={handleChangeCloseConsignment}
							>
								{consignmentListOpenAndInAccra.length > 0
									? consignmentListOpenAndInAccra.map((consignmentInTurkey) => (
											<MenuItem key={consignmentInTurkey.id} value={consignmentInTurkey.id}>
												{consignmentInTurkey.name}
											</MenuItem>
									  ))
									: null}
							</Select>
						</FormControl>
						<Button className={classes.button} variant="contained" onClick={handleCloseConsignment}>
							close
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default Consignment;
