import React from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import UpdateCustomerOnEditForm from './UpdateCustomerOnEditForm';
import CustomerTransactionDetails from './CustomerTransactionDetails';
import CircularProgress from '@mui/material/CircularProgress';

export default function CustomersTable(props) {
	const { filterCustomers, customers } = props;
	const [open, setOpen] = React.useState(false);
	const [openCustomerDetails, setOpenCustomerDetails] = React.useState(false);
	const [customerDetails, setCustomerDetails] = React.useState('');
	const [customerUsername, setCustomerUsername] = React.useState('');
	const [customerTransferDetails, setCustomerTransferDetails] = React.useState('');
	const [customerTransferFreightDetails, setCustomerTransferFreightDetails] = React.useState('');
	const [customerSupplyPaymentDetails, setCustomerSupplyPaymentDetails] = React.useState('');
	const [error, setError] = React.useState('');
	const token = sessionStorage.getItem('Token');

	function getCustomerDetailsByUsername(selectUsername) {
		// console.log(selectUsername);
		if (selectUsername !== '') {
			let baseurl =
				'https://rockmanapi.nexlogssolutions.com/api/customerdetailsByUsername/' + selectUsername + '/';
			// let baseurl = 'http://127.0.0.1:8000/api/customerdetailsByUsername/' + selectUsername + '/';
			axios
				.get(baseurl, {
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				})
				.then((res) => {
					setCustomerDetails(res.data);
				})
				.catch((error) => {
					setError(error);
				});
		}
	}

	function getCustomerTransferDetails(selectUsername) {
		console.log(selectUsername);
		if (selectUsername !== '') {
			let baseurl = 'https://rockmanapi.nexlogssolutions.com/api/customerTransfersView/' + selectUsername + '/';
			// let baseurl = 'http://127.0.0.1:8000/api/customerTransfersView/' + selectUsername + '/';
			axios
				.get(baseurl, {
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				})
				.then((res) => {
					setCustomerTransferDetails(res.data);
				})
				.catch((error) => {
					setError(error);
				});
		}
	}

	function getCustomerTransferFreightDetails(selectUsername) {
		console.log(selectUsername);
		if (selectUsername !== '') {
			let baseurl =
				'https://rockmanapi.nexlogssolutions.com/api/customertransfersfreightView/' + selectUsername + '/';
			// let baseurl = 'http://127.0.0.1:8000/api/customertransfersfreightView/' + selectUsername + '/';
			axios
				.get(baseurl, {
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				})
				.then((res) => {
					setCustomerTransferFreightDetails(res.data);
				})
				.catch((error) => {
					setError(error);
				});
		}
	}

	function getCustomerSupplyPaymentDetails(selectUsername) {
		console.log(selectUsername);
		if (selectUsername !== '') {
			let baseurl =
				'https://rockmanapi.nexlogssolutions.com/api/customerSupplyPaymentView/' + selectUsername + '/';
			// let baseurl = 'http://127.0.0.1:8000/api/customerSupplyPaymentView/' + selectUsername + '/';
			axios
				.get(baseurl, {
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				})
				.then((res) => {
					setCustomerSupplyPaymentDetails(res.data);
				})
				.catch((error) => {
					setError(error);
				});
		}
	}

	const handleClickOpenCustomerDetails = (selectedUsername) => {
		setOpenCustomerDetails(true);
		setCustomerUsername(selectedUsername);
		getCustomerTransferDetails(selectedUsername);
		getCustomerTransferFreightDetails(selectedUsername);
		getCustomerSupplyPaymentDetails(selectedUsername);
	};
	const handleClickCloseCustomerDetails = () => {
		setOpenCustomerDetails(false);
	};

	const handleClickOpenEdit = (username) => {
		getCustomerDetailsByUsername(username);
		setOpen(true);
		setOpenCustomerDetails(false);
	};
	const handleClickCloseEdit = () => {
		setOpen(false);
	};

	if (customers.length == 0) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
			>
				<CircularProgress />
			</div>
		);
	}

	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell sx={{ paddingLeft: '1.5rem', fontWeight: 600, width: '20%' }}>
								Username{' '}
							</TableCell>
							<TableCell align="right" sx={{ fontWeight: 600, width: '15%' }}>
								Transfer Balance
							</TableCell>
							<TableCell align="center" sx={{ fontWeight: 600, width: '15%' }}>
								Freight Balance
							</TableCell>
							<TableCell align="center" sx={{ fontWeight: 600, width: '15%' }}>
								Total Balance
							</TableCell>
							<TableCell align="center" sx={{ fontWeight: 600 }}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filterCustomers.length == 0
							? customers.slice(0, 50).map((customer) => (
									<TableRow
										key={customer.id}
										sx={{
											'&:last-child td, &:last-child th': { border: 0 },
											'&:hover': { backgroundColor: '#d4d4d4' },
										}}
									>
										<TableCell
											component="th"
											scope="row"
											sx={{ paddingLeft: '1.5rem' }}
											onClick={() => handleClickOpenCustomerDetails(customer.user_id.username)}
										>
											{customer.user_id.username}
										</TableCell>
										<TableCell align="center">
											{Math.round(customer.tranfer_account_balance)}
										</TableCell>
										<TableCell align="center">
											{Math.round(customer.freight_account_balance)}
										</TableCell>
										<TableCell align="center">
											{Math.round(
												customer.tranfer_account_balance + customer.freight_account_balance
											)}
										</TableCell>
										<TableCell align="right">
											<Button
												sx={{ width: '50%', textTransform: 'capitalize' }}
												size="medium"
												variant="contained"
												onClick={() => handleClickOpenEdit(customer.user_id.username)}
											>
												Edit
											</Button>
										</TableCell>
									</TableRow>
							  ))
							: filterCustomers.slice(0, 10).map((customer) => (
									<TableRow
										key={customer.id}
										sx={{
											'&:last-child td, &:last-child th': { border: 0 },
											'&:hover': { backgroundColor: '#d4d4d4' },
										}}
									>
										<TableCell
											component="th"
											scope="row"
											sx={{ paddingLeft: '1.5rem' }}
											onClick={() => handleClickOpenCustomerDetails(customer.user_id.username)}
										>
											{customer.user_id.username}
										</TableCell>
										<TableCell align="center">
											{Math.round(customer.tranfer_account_balance)}
										</TableCell>
										<TableCell align="center">
											{Math.round(customer.freight_account_balance)}
										</TableCell>
										<TableCell align="center">
											{Math.round(
												customer.tranfer_account_balance + customer.freight_account_balance
											)}
										</TableCell>

										<TableCell align="right">
											<Button
												sx={{ width: '50%', textTransform: 'capitalize' }}
												size="medium"
												variant="contained"
												onClick={() => handleClickOpenEdit(customer.user_id.username)}
											>
												Edit
											</Button>
										</TableCell>
									</TableRow>
							  ))}
					</TableBody>
				</Table>
			</TableContainer>
			<UpdateCustomerOnEditForm
				openEditCustomer={open}
				onCloseEditCustomer={handleClickCloseEdit}
				customerDetails={customerDetails}
			/>
			<CustomerTransactionDetails
				openCustomerTransactionDetails={openCustomerDetails}
				CloseCustomerTransactionDetails={handleClickCloseCustomerDetails}
				customerTransferDetails={customerTransferDetails}
				customerUsername={customerUsername}
				customerSupplyPaymentDetails={customerSupplyPaymentDetails}
				customerTransferFreightDetails={customerTransferFreightDetails}
			/>
		</>
	);
}
