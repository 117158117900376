import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';


const TransferPaymentListTable = (props) => {
	const { TransferPayments, filterTransfer } = props;

	if (TransferPayments.length == 0) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
			>
				<CircularProgress />
			</div>
		);
	}

	return (
		<div>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell sx={{ paddingLeft: '1.5rem', fontWeight: 600 }}>Username </TableCell>
							<TableCell align="right" sx={{ fontWeight: 600 }}>
								Total
							</TableCell>
							<TableCell align="right" sx={{ fontWeight: 600 }}>
								Cash Dollar
							</TableCell>
							<TableCell align="right" sx={{ fontWeight: 600 }}>
								Cash Cedis
							</TableCell>
							<TableCell align="right" sx={{ fontWeight: 600 }}>
								Momo Cedis
							</TableCell>
							<TableCell align="right" sx={{ fontWeight: 600 }}>
								Bank Dollar
							</TableCell>
							<TableCell align="right" sx={{ fontWeight: 600 }}>
								Bank Cedis
							</TableCell>
							<TableCell align="right" sx={{ fontWeight: 600 }}>
								Date
							</TableCell>
						</TableRow>
					</TableHead>
					{filterTransfer.length != 0 ? (
						<TableBody>
							{filterTransfer.map((TransferPayment) => (
								<TableRow
									key={TransferPayment.id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component="th" scope="row" sx={{ paddingLeft: '1.5rem' }}>
										{TransferPayment.customer_id.user_id.username}
									</TableCell>
									<TableCell align="right">{TransferPayment.amount_sent_dollars.toFixed(2)}</TableCell>
									<TableCell align="right">{TransferPayment.cash}</TableCell>
									<TableCell align="right">{TransferPayment.cash_cedis}</TableCell>
									<TableCell align="right">{TransferPayment.momo_cedis}</TableCell>
									<TableCell align="right">{TransferPayment.bank}</TableCell>
									<TableCell align="right">{TransferPayment.bank_cedis}</TableCell>
									<TableCell align="right">{TransferPayment.date}</TableCell>
								</TableRow>
							))}
						</TableBody>
					) : (
						<TableBody>
							{TransferPayments.slice(0, 300).map((TransferPayment) => (
								<TableRow
									key={TransferPayment.id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell component="th" scope="row" sx={{ paddingLeft: '1.5rem' }}>
										{TransferPayment.customer_id.user_id.username}
									</TableCell>
									<TableCell align="right">{TransferPayment.amount_sent_dollars.toFixed(2)}</TableCell>
									<TableCell align="right">{TransferPayment.cash}</TableCell>
									<TableCell align="right">{TransferPayment.cash_cedis}</TableCell>
									<TableCell align="right">{TransferPayment.momo_cedis}</TableCell>
									<TableCell align="right">{TransferPayment.bank}</TableCell>
									<TableCell align="right">{TransferPayment.bank_cedis}</TableCell>
									<TableCell align="right">{TransferPayment.date}</TableCell>
								</TableRow>
							))}
						</TableBody>
					)}
				</Table>
			</TableContainer>
		</div>
	);
};

export default TransferPaymentListTable;
