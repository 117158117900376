import React from 'react';
import Typography from '@mui/material/Typography';
import { Button, Grid, Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import GetFreightReport from '../components/GetFreightReport';
import { useNavigate } from 'react-router-dom';


function Report() {
	// const [date, setDate] = React.useState('');
	// const [totalTransfersOfDay, setTotalTransfersOfDay] = React.useState('');
	// const [totalSupplierPaymentsOfDay, setTotalSupplierPaymentsOfDay] = React.useState('');
	let navigate = useNavigate();


	// function getTransferSupplierPaymentSum(date) {
	// 	if (date !== '') {
	// 		// let baseurl = 'https://rockmanapi.nexlogssolutions.com/api/tranfersupplierpaymentsum/' + date + '/';
	// 		let baseurl = 'http://127.0.0.1:8000/api/tranfersupplierpaymentsum/' + date + '/';
	// 		console.log(baseurl);
	// 		axios
	// 			.get(baseurl)
	// 			.then((res) => {
	// 				setTotalTransfersOfDay(res.data.amount_sent_dollars);
	// 				setTotalSupplierPaymentsOfDay(res.data.goods_cost_dollars);
	// 			})
	// 			.catch((error) => {
	// 				setError(error);
	// 			});
	// 	}
	// }

	// const handleSubmit = (e) => {
	// 	e.preventDefault();
	// 	getTransferSupplierPaymentSum(date);
	// };

	const handleOpenFreightReport = () => {
		navigate('/freightreport')
	};
	const handleOpenConsignmentFreightReport = () => {
		navigate('/consignmentfreightreport')
	};

	return (
		<Grid container>
			<Grid id="sidebarContainer" item md={2}>
				<Sidebar />
			</Grid>
			<Grid item container md={10} sx={{ px:5, my:5 }}>
				<Grid item md={6} sx={{mt:3, display:'flex', justifyContent:'center'}}>
					<Button sx={{ width: '90%' }} variant="contained" onClick={handleOpenFreightReport}>
						Customers Freight Report
					</Button>
				</Grid>
				<Grid item md={6} sx={{mt:3, display:'flex', justifyContent:'center'}}>
					<Button sx={{ width: '90%' }} variant="contained" onClick={handleOpenConsignmentFreightReport}>
						Consignment Freight Report
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default Report;
