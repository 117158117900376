import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

function AddFreightForm(props) {
	const [usernameEntered, setUsernameEntered] = React.useState('');
	const [selectedUsername, setSelectedUsername] = React.useState('');
	const [selectedWeight, setSelectedWeight] = React.useState('');
	const [selectedConsignmentID, setSelectedConsignmentID] = React.useState('');
	const [totalWeightCost, setTotalWeightCost] = React.useState(0);
	const [filteredData, setFilteredData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [openConsignment, setOpenConsignment] = React.useState([]);
	const [consignmentData, setConsignmentData] = React.useState(null);
	const token = sessionStorage.getItem('Token');

	const { open, onClose, users } = props;

	const onSelectUsernameFromListHandler = (selectedUsername) => {
		setSelectedUsername(selectedUsername);
		setFilteredData([]);
	};

	const handleConsignmentChange = (e) => {
		const selectedConsignmentValue = e.target.value;
		setSelectedConsignmentID(selectedConsignmentValue);
		formik.setFieldValue('selectedConsignment', selectedConsignmentValue);

		if (formik.values.selectedConsignment && !selectedConsignmentValue) {
			// Clear the verifyBank field
			formik.setFieldValue('freightCost', '');
		}
		// Update the bank field
		formik.handleChange(e);
	};

	// const handleBankChange = (e) => {
	// 	const bankValue = e.target.value;

	// 	// Check if the bank field was filled and then removed
	// 	if (formik.values.bank && !bankValue) {
	// 		// Clear the verifyBank field
	// 		formik.setFieldValue('verifyBank', '');
	// 	}

	// 	// Update the bank field
	// 	formik.handleChange(e);
	// };

	// const handleCashChange = (e) => {
	// 	const bankValue = e.target.value;

	// 	// Check if the bank field was filled and then removed
	// 	if (formik.values.cash && !bankValue) {
	// 		// Clear the verifyBank field
	// 		formik.setFieldValue('verifyCash', '');
	// 	}

	// 	// Update the bank field
	// 	formik.handleChange(e);
	// };

	// const handleMomoChange = (e) => {
	// 	const bankValue = e.target.value;

	// 	// Check if the bank field was filled and then removed
	// 	if (formik.values.momo && !bankValue) {
	// 		// Clear the verifyBank field
	// 		formik.setFieldValue('verifyMomo', '');
	// 	}

	// 	// Update the bank field
	// 	formik.handleChange(e);
	// };
	const PostFreight = async (values) => {
		const freightPaymentValue = {
			username: values.username,
			total_cost: values.freightCost,
			total_weight: selectedWeight ? selectedWeight : 0,
			goods_desc: values.description,
			payment_id: {
				status: 'invalid',
				payment_mode: 'invalid',
				balance: 0,
				dept: 0,
				transaction_type: 'Freight',
			},
			selectedConsignment: values.selectedConsignment,
		};

		try {
			const response = await axios.post(
				'https://rockmanapi.nexlogssolutions.com/api/customerFreightView/',
				freightPaymentValue,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			// const response = await axios.post('http://127.0.0.1:8000/api/customerFreightView/', freightPaymentValue, {
			// 	headers: {
			// 		Authorization: `Token ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// });
			setLoading(false);
			alert('Freight Added Successfully');
		} catch (err) {
			setLoading(false);
			alert('Error : System failed to add freight, please contact your administrator');
		}
	};

	const formik = useFormik({
		initialValues: {
			username: '',
			totalWeight: '',
			freightCost: '',
			description: '',
			selectedConsignment: '',
		},
		validationSchema: Yup.object({
			username: Yup.string().max(15, 'Must be 15 characters or less'),
			// selectedConsignment: Yup.number().required('Required'),
		}),
		onSubmit: (values, { resetForm }) => {
			values.username = selectedUsername;
			setLoading(true);
			PostFreight(values);
			resetForm();
		},
	});
	React.useEffect(() => {
		axios
			.get('https://rockmanapi.nexlogssolutions.com/api/consignmentlist/', {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'application/json',
				},
			})
			// .get('http://127.0.0.1:8000/api/consignmentlist/', {
			// 	headers: {
			// 		Authorization: `Token ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// })
			.then((res) => {
				setOpenConsignment(res.data);
			})
			.catch((error) => {
				alert('consignment not fetched !!!, contact you administrator');
			});
	}, []);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				// Check if selectedConsignmentID is not an empty string
				if (selectedConsignmentID !== '') {
					const response = await axios.get(
						`https://rockmanapi.nexlogssolutions.com/api/consignmentbyid/${selectedConsignmentID}/`,
						// `http://127.0.0.1:8000/api/consignmentbyid/${selectedConsignmentID}/`,
						{
							headers: {
								Authorization: `Token ${token}`,
								'Content-Type': 'application/json',
							},
						}
					);
					setConsignmentData(response.data);
				}
			} catch (error) {
				console.error('Error fetching consignment data:', error);
			}
		};

		fetchData();
	}, [selectedConsignmentID]);

	return (
		<>
			<Dialog open={open} onClose={onClose}>
				<DialogTitle sx={{ textAlign: 'center', fontSize: 'xx-large', paddingBottom: '1.5rem' }}>
					Add Freight Payment
				</DialogTitle>
				<DialogContent>
					<Box>
						<form onSubmit={formik.handleSubmit}>
							<Grid container spacing={2}>
								<Grid item sm={6}>
									<TextField
										size="small"
										margin="dense"
										id="username"
										label="Username"
										fullWidth
										variant="outlined"
										value={selectedUsername}
										onChange={(e) => {
											const searchWord = e.target.value;
											setUsernameEntered(searchWord);
											setSelectedUsername(searchWord);
											const newFilter = users.filter((value) => {
												return value.user_id.username
													.toLowerCase()
													.includes(searchWord.toLowerCase());
											});
											if (searchWord === '') {
												setFilteredData([]);
											} else {
												setFilteredData(newFilter);
											}
										}}
									/>
									{filteredData.length != 0 && (
										<div className="dataResult">
											{filteredData.slice(0, 10).map((value, key) => {
												return (
													<div
														key={key}
														onClick={() => {
															onSelectUsernameFromListHandler(value.user_id.username);
														}}
													>
														<p>{value.user_id.username} </p>
													</div>
												);
											})}
										</div>
									)}
								</Grid>
								<Grid item md={6} sx={{ mt: 1 }}>
									<TextField
										select
										// labelId="selectedConsignment"
										id="selectedConsignment"
										name="selectedConsignment"
										label="Consignment number"
										value={formik.values.selectedConsignment}
										onChange={handleConsignmentChange}
										onBlur={formik.handleBlur}
										error={formik.touched.selectedConsignment && formik.errors.selectedConsignment}
										fullWidth
										variant="outlined"
										size="small"
										sx={{ color: 'red' }}
									>
										{openConsignment &&
											openConsignment.map((consignment) => (
												<MenuItem key={consignment.id} value={consignment.id}>
													{consignment.name}
												</MenuItem>
											))}
									</TextField>
								</Grid>
								{consignmentData && consignmentData.shipping_mode == 'air' ? (
									<>
										<Grid item sm={6}>
											<TextField
												size="small"
												margin="dense"
												id="totalWeight"
												label="Total Weight"
												fullWidth
												variant="outlined"
												value={selectedWeight}
												onChange={(e) => {
													const EnteredWeight = e.target.value;
													setSelectedWeight(EnteredWeight);
													setTotalWeightCost(EnteredWeight * 10.5);
												}}
												onBlur={formik.handleBlur}
												error={formik.touched.totalWeight && formik.errors.totalWeight}
											/>
										</Grid>
										<Grid item sm={6}>
											<TextField
												size="small"
												margin="dense"
												id="freightCost"
												label="Freight Cost"
												fullWidth
												variant="outlined"
												disabled
												value={totalWeightCost}
											/>
										</Grid>
									</>
								) : (
									<>
										<Grid item sm={6}>
											<TextField
												size="small"
												margin="dense"
												id="totalWeight"
												label="Total Weight"
												fullWidth
												variant="outlined"
												value={selectedWeight}
												onChange={(e) => {
													const EnteredWeight = e.target.value;
													setSelectedWeight(EnteredWeight);
												}}
												onBlur={formik.handleBlur}
												error={formik.touched.totalWeight && formik.errors.totalWeight}
											/>
										</Grid>
										<Grid item sm={6}>
											<TextField
												size="small"
												margin="dense"
												id="freightCost"
												label="Freight Cost"
												fullWidth
												variant="outlined"
												value={formik.values.freightCost}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												error={formik.touched.freightCost && formik.errors.freightCost}
											/>
										</Grid>
									</>
								)}

								<Grid item sm={12}>
									<TextField
										multiline
										rows={4}
										size="small"
										margin="dense"
										id="description"
										label="Description"
										fullWidth
										variant="outlined"
										value={formik.values.description}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.description && formik.errors.description}
									/>
								</Grid>
							</Grid>

							<Grid item>
								<Box
									sx={{
										gap: '2rem',
										display: 'flex',
										justifyContent: 'center !important',
										paddingBlock: '1rem',
									}}
								>
									<Button sx={{ width: '40%' }} variant="contained" onClick={onClose}>
										Cancel
									</Button>
									<Button
										sx={{ width: '40%', marginLeft: '0 !important' }}
										variant="contained"
										type="submit"
									>
										Submit
									</Button>
									{loading ? <CircularProgress sx={{ position: 'absolute', top: '50%' }} /> : null}
								</Box>
							</Grid>
						</form>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
}

export default AddFreightForm;
