import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import axios from 'axios';

const DeleteSupplierPayment = (props) => {
	const { selectedID, setSelectedID, open, setOpen, CloseCustomerTransactionDetails } = props;
	const token = sessionStorage.getItem('Token');

	const handleDeleteConfirmation = async () => {
		try {
			const response = await axios.delete(
				`https://rockmanapi.nexlogssolutions.com/api/supplierpayment/${selectedID}/`,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			// const response = await axios.delete(`http://127.0.0.1:8000/api/supplierpayment/${selectedID}/`);
			if (response.status == 204) {
				setOpen(false);
				setSelectedID(null);
				CloseCustomerTransactionDetails();
			}
		} catch (error) {
			alert('Error deleting record:', error);
		}
	};

	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DialogContent sx={{ textAlign: 'center' }}>
				<p style={{ marginBottom: '8px' }}>Are you sure you want to delete?</p>
				<div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
					<Button onClick={handleDeleteConfirmation} variant="contained" color="error">
						Yes
					</Button>
					<Button onClick={() => setOpen(false)} variant="outlined" color="primary">
						No
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteSupplierPayment;
