import React, {createContext, useState} from 'react'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({})
    const [currencyRate, setCurrencyRate] = useState('')

    return (
        <AuthContext.Provider value={{auth, setAuth, currencyRate, setCurrencyRate}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;