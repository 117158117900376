import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteTransferEntry from './DeleteTransferEntry';
import DeleteSupplierPayment from './DeleteSupplierPayment';

function CustomerTransactionDetails(props) {
	const {
		openCustomerTransactionDetails,
		CloseCustomerTransactionDetails,
		customerTransferDetails,
		customerUsername,
		customerSupplyPaymentDetails,
		customerTransferFreightDetails,
	} = props;

	const [open, setOpen] = useState(false);
	const [openTransferDelete, setOpenTransferDelete] = useState(false);
	const [selectedID, setSelectedID] = useState(null);

	const handleClickOpenDelete = (id) => {
		setOpen(true);
		setSelectedID(id);
	};
	const handleClickOpenDeleteTransfer = (id) => {
		setOpenTransferDelete(true);
		setSelectedID(id);
	};

	return (
		<>
			<Dialog open={openCustomerTransactionDetails} maxWidth="lg">
				<DialogContent>
					<Typography sx={{ color: '#000', fontSize: 'larger', fontWeight: 700, marginBottom: '1.5rem' }}>
						{customerUsername} <span> - Transaction Details </span>
					</Typography>
					<Typography sx={{ fontSize: 'large', marginBottom: '.5rem' }}>Transfer Summary</Typography>
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ paddingLeft: '1.5rem', fontWeight: 600 }}>
										Amount Sent($)
									</TableCell>

									<TableCell align="right" sx={{ fontWeight: 600 }}>
										Date
									</TableCell>
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							{customerTransferDetails.length != 0 ? (
								<TableBody>
									{customerTransferDetails.map((TransferPayment) => (
										<TableRow
											key={TransferPayment.id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="th" scope="row" sx={{ paddingLeft: '1.5rem' }}>
												{Math.round(TransferPayment.amount_sent_dollars)}
											</TableCell>
											<TableCell align="right">{TransferPayment.date}</TableCell>
											<TableCell align="right">
												<Button
													sx={{ width: '50%', textTransform: 'capitalize' }}
													size="medium"
													variant="contained"
													onClick={() => handleClickOpenDeleteTransfer(TransferPayment.id)}
												>
													Delete
												</Button>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<TableBody>
									<TableRow>
										<TableCell align="right">No transfer in the name of this customer</TableCell>
									</TableRow>
								</TableBody>
								// <Typography>No transfer in the name of this customer </Typography>
							)}
						</Table>
					</TableContainer>

					<Typography sx={{ fontSize: 'large', marginBottom: '.5rem', marginTop: '2rem' }}>
						Supply Payment Summary
					</Typography>
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ paddingLeft: '1.5rem', fontWeight: 600 }}>Goods Cost($)</TableCell>
									<TableCell align="right" sx={{ fontWeight: 600 }}>
										Supplier
									</TableCell>
									<TableCell align="right" sx={{ fontWeight: 600 }}>
										Date
									</TableCell>
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							{customerSupplyPaymentDetails.length != 0 ? (
								<TableBody>
									{customerSupplyPaymentDetails.map((SupplyPayment) => (
										<TableRow
											key={SupplyPayment.id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="th" scope="row" sx={{ paddingLeft: '1.5rem' }}>
												{Math.round(SupplyPayment.goods_cost_dollars)}
											</TableCell>
											<TableCell align="right">
												{SupplyPayment.supplier_id.company_name}
											</TableCell>
											<TableCell align="right">{SupplyPayment.created_at}</TableCell>
											<TableCell align="right">
												<Button
													sx={{ width: '50%', textTransform: 'capitalize' }}
													size="medium"
													variant="contained"
													onClick={() => handleClickOpenDelete(SupplyPayment.id)}
												>
													Delete
												</Button>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<TableBody>
									<TableRow>
										<TableCell align="right">
											No Supplier payment in the name of this customer
										</TableCell>
									</TableRow>
								</TableBody>
							)}
						</Table>
					</TableContainer>

					<Typography sx={{ fontSize: 'large', marginBottom: '.5rem', marginTop: '2rem',}}>Freight Summary</Typography>
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ paddingLeft: '1.5rem', fontWeight: 600 }}>Total Weight</TableCell>
									<TableCell sx={{ paddingLeft: '1.5rem', fontWeight: 600 }}>Total Amount</TableCell>
									<TableCell align="right" sx={{ fontWeight: 600 }}>
										Date
									</TableCell>
								</TableRow>
							</TableHead>
							{customerTransferFreightDetails.length != 0 ? (
								<TableBody>
									{customerTransferFreightDetails.map((TransferPayment) => (
										<TableRow
											key={TransferPayment.id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="th" scope="row" sx={{ paddingLeft: '1.5rem' }}>
												{TransferPayment.total_weight}
											</TableCell>
											<TableCell align="center">
												{Math.round(TransferPayment.total_cost)}
											</TableCell>
											<TableCell align="right">{TransferPayment.date_updated}</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<TableBody>
									<TableRow>
										<TableCell align="right">No transfer in the name of this customer</TableCell>
									</TableRow>
								</TableBody>
							)}
						</Table>
					</TableContainer>
					<Box
						sx={{
							gap: '6rem',
							display: 'flex',
							justifyContent: 'center !important',
							paddingBlock: '1rem',
						}}
					>
						<Button sx={{ width: '20%' }} variant="contained" onClick={CloseCustomerTransactionDetails}>
							Cancel
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
			<DeleteTransferEntry
				CloseCustomerTransactionDetails={CloseCustomerTransactionDetails}
				selectedID={selectedID}
				setSelectedID={setSelectedID}
				open={openTransferDelete}
				setOpen={setOpenTransferDelete}
			/>
			<DeleteSupplierPayment
				CloseCustomerTransactionDetails={CloseCustomerTransactionDetails}
				selectedID={selectedID}
				setSelectedID={setSelectedID}
				open={open}
				setOpen={setOpen}
			/>
		</>
	);
}

export default CustomerTransactionDetails;
