import React, { useEffect, useState } from 'react';
import { Grid, Typography, Select, MenuItem, InputLabel, CircularProgress, Button } from '@mui/material';
import { useFormik } from 'formik';
import Sidebar from '../components/Sidebar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';

const FreightReport = () => {
	const [openConsignment, setOpenConsignment] = useState([]);
	const [accFreights, setAccFreights] = useState([]);
	const [loading, setLoading] = useState(false);
	let navigate = useNavigate();
	const token = sessionStorage.getItem('Token');

	const handleGoBack = () => {
		navigate(-1);
	};

	const PostConsignmentDebtorsByName = async (values) => {
		setLoading(true);

		const consignmentDebtorsParams = {
			from_date: values.update_date_from.format('YYYY-MM-DD'),
			to_date: values.update_date_to.format('YYYY-MM-DD'),
			consignment_names: values.selectedValues,
		};
		try {
			const response = await axios.post(
				'https://rockmanapi.nexlogssolutions.com/api/freightconsignmentaccbyconsignmentids/',
				consignmentDebtorsParams,
				// 'http://127.0.0.1:8000/api/freightconsignmentaccbyconsignmentids/',
				// consignmentDebtorsParams,
				{
					headers: {
						Authorization: `Token ${token}`,
						'Content-Type': 'application/json',
					},
				}
			);
			setLoading(false);

			setAccFreights(response.data);
		} catch (err) {
			// Handle Error Here
			setLoading(false);
			alert(err.message);
		}
	};
	const formik = useFormik({
		initialValues: {
			selectedValues: [],
			update_date_from: dayjs(),
			update_date_to: dayjs(),
		},
		onSubmit: (values) => {
			PostConsignmentDebtorsByName(values);
		},
	});
	useEffect(() => {
		axios
			.get('https://rockmanapi.nexlogssolutions.com/api/consignmentlist/', {
				headers: {
					Authorization: `Token ${token}`,
					'Content-Type': 'application/json',
				},
			})
			// .get('http://127.0.0.1:8000/api/consignmentlist/', {
			// 	headers: {
			// 		Authorization: `Token ${token}`,
			// 		'Content-Type': 'application/json',
			// 	},
			// })
			.then((res) => {
				setOpenConsignment(res.data);
			})
			.catch((error) => {
				alert('consignment not fetched !!!, contact you administrator');
			});
	}, []);
	return (
		<Grid container>
			<Grid id="sidebarContainer" item md={2}>
				<Sidebar />
			</Grid>
			<Grid item container md={10} sx={{ px: 5 }}>
				<Grid item md={12} sx={{ mt: 3 }}>
					<Button sx={{ width: 200 }} type="submit" variant="contained" onClick={handleGoBack}>
						Back
					</Button>
				</Grid>
				<Grid item md={12}>
					<Box>
						<Typography sx={{ mt: 4 }} variant="h6">
							Freight Debtors
						</Typography>
					</Box>
					<form onSubmit={formik.handleSubmit}>
						<Grid container spacing={2} sx={{ display: 'flex', alignItems: 'flex-end' }}>
							<Grid item md={4}>
								{/* <FormControl fullWidth> */}
								<InputLabel id="demo-mutiple-checkbox-label">Select Consignment</InputLabel>
								<Select
									sx={{ pt: 0.1, height: '2.5rem', width: '100%' }}
									labelId="demo-mutiple-checkbox-label"
									id="selectedValues"
									name="selectedValues"
									multiple
									value={formik.values.selectedValues}
									onChange={formik.handleChange}
									renderValue={(selectedValues) => selectedValues.join(', ')}
								>
									{openConsignment &&
										openConsignment.map((consignment) => (
											<MenuItem key={consignment.id} value={consignment.name}>
												{consignment.name}
											</MenuItem>
										))}
								</Select>
								{/* </FormControl> */}
							</Grid>
							<Grid item md={2}>
								<LocalizationProvider dateAdapter={AdapterDayjs} locale="en" size="small">
									<InputLabel htmlFor="update_date_from">Update Date from</InputLabel>

									<DatePicker
										id="update_date_from"
										name="update_date_from"
										slotProps={{ textField: { size: 'small' } }}
										value={formik.values.update_date_from}
										// onChange={formik.handleChange}
										onChange={(date) => formik.setFieldValue('update_date_from', date)}
										onBlur={formik.handleBlur}
										error={formik.touched.update_date_from && !!formik.errors.update_date_from}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item md={2}>
								<LocalizationProvider dateAdapter={AdapterDayjs} locale="en" size="small">
									<InputLabel htmlFor="update_date_from">Update Date to</InputLabel>

									<DatePicker
										id="update_date_to"
										name="update_date_to"
										slotProps={{ textField: { size: 'small' } }}
										value={formik.values.update_date_to}
										onChange={(date) => formik.setFieldValue('update_date_to', date)}
										onBlur={formik.handleBlur}
										error={formik.touched.update_date_to && !!formik.errors.update_date_to}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item md={2}>
								<Button fullWidth type="submit" variant="contained">
									Submit
								</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
				<Grid item md={12} sx={{ mt: 5 }}>
					<Box>
						<Typography sx={{ mt: 4 }} variant="p">
							Results
						</Typography>
					</Box>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 100 }} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell sx={{ width: 100, paddingLeft: '1.5rem', fontWeight: 600 }}>
										Username
									</TableCell>
									<TableCell align="center" sx={{ width: 130, fontWeight: 600 }}>
										Total Cost($)
									</TableCell>
									<TableCell align="center" sx={{ width: 130, fontWeight: 600 }}>
										Total Paid($)
									</TableCell>
									<TableCell align="center" sx={{ width: 130, fontWeight: 600 }}>
										Balance
									</TableCell>
									<TableCell align="center" sx={{ fontWeight: 600 }}>
										Consignment Name
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{accFreights.length == 0 ? null : loading ? (
									<CircularProgress sx={{ display: 'block', margin: '0 auto' }} />
								) : (
									accFreights.map((freights) => (
										<TableRow
											key={freights.username}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell
												component="th"
												scope="row"
												sx={{ width: 100, paddingLeft: '1.5rem' }}
											>
												{freights.username}
											</TableCell>
											<TableCell align="center">{Math.round(freights.total_cost)}</TableCell>
											<TableCell align="center">{Math.round(freights.total_paid)}</TableCell>
											<TableCell align="center">{Math.round(freights.total_balance)}</TableCell>
											<TableCell align="center" sx={{ width: 600 }}>
												{freights.consignment_numbers}
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default FreightReport;
